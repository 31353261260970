<template lang="pug">
.form-input(
  :class="{ 'form-input--icon-left': iconLeft, 'form-input--icon-right': iconRight }"
)
  i.far.form-input--icon.left(
    v-if="iconLeft"
    :class="iconLeft"
    @click="$emit('left-icon-click')"
  )
  input(
    :value="value"
    ref="forminput"
    :name="name"
    :placeholder="`${placeholder} ${required ? '*' : ''}`"
    :type="typeComputed"
    :maxlength="max"
    :class="{ 'is-invalid': invalid, ...classInput }"
    @input="event => $emit('input', event.target.value)"
  )
  i.far.form-input--icon.right(
    v-if="iconRight"
    :class="iconRight"
    @click="$emit('right-icon-click')"
  )
  i.far.form-input--icon.right(
    v-if="type === 'password'"
    :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
    @click="showPassword = !showPassword"
  )
  span.form-input--validation-message(v-if="invalid && validationMessage")
    | {{ validationMessage }}
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    classInput: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    typeComputed() {
      if (this.type === 'password') {
        return !this.showPassword ? 'password' : 'text';
      }
      return this.type;
    },
  },
};
</script>

<style lang="scss">
.form-input {
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    max-height: 48px;
    padding: 15px;
    border: 1px solid $grey10;
    border-radius: $radius;
    color: $shadow;
    outline: 0;
    font-size: 14px;
    font-family: $fontDmSans;
    line-height: 14px;
    transition: $transition;
    box-sizing: border-box;
    -webkit-appearance: none;

    &:focus {
      border: 1px solid $grey20;
    }

    &::placeholder {
      color: $shadow;
      font-family: $fontDmSans;
      line-height: normal;
    }

    &.is-invalid {
      border: 1px solid $red;
    }
  }

  &.fullwidth {
    width: 100%;
  }

  &--validation-message {
    margin-top: 5px;
    color: $red;
    font-size: 11px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &--icon {
    position: absolute;
    top: 1px;
    max-height: 14px;
    padding: 15px;
    border-radius: $radius;
    color: $hunterGreen;
    font-size: 16px;
    cursor: pointer;
    background: $white;

    &.left {
      left: 1px;
    }

    &.right {
      right: 1px;
    }
  }
}
</style>
